.welcomeSider {
    margin: 16px;
    border-radius: 5px;
    padding: 5px;
    font-size: 1rem;
    text-align: center;
  }

.title{
  font-size: 2.5rem;
  font-weight: bold;
}

.titleBar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px;
}

.trigger {
  padding: 0 24px;
  font-size: 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.footer {
  text-align: center;
}